.btn41-43 {
    padding: 10px 25px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    background: transparent;
    outline: none !important;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
  }
  
  .btn-43 {
    border: 2px solid #fff; /* Golden color */
    border-radius: 12px;
    z-index: 1;
    color: #fff; 
    background-color: transparent;
  }
  
  .btn-43:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 0;
    top: 0;
    left: 0;
    z-index: 10;
    border-radius: 11px;
    background: #00072D; /* Golden color */
    background: linear-gradient(to right, #3B75B2, #00072D); /* Golden color gradient */
    transition: all 0.3s ease;
  }
  
  .btn-43:hover {
    color: #000000;
  }
  
  .btn-43:hover:after {
    top: auto;
    bottom: 0;
    height: 100%;
  }
  
