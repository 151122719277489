/* Footer.css */

/* Define the animation */
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  
  40% {
    transform: translateY(-20px);
  }

  60% {
    transform: translateY(-10px);
  }
}
  
  /* Apply the animation to the logo class */
  .logo {
    animation: bounce 2s infinite; /* Adjust duration and timing function as needed */
  }
  .imagesfooter {
    margin-left: 50px;
   }
  @media (min-width: 769px) {
    .imagesfooter {
     margin-left: -100px;
    }
  }
  @media (max-width: 768px) {
    .imagesfooter {
      display: flex;
      justify-content: center; /* Center items horizontally */
      align-items: center; /* Center items vertically */
    }
  }
  
  @media (max-width: 450px) {
    .imagesfooter {
      display: flex;
      flex-direction: column; /* Stack items vertically */
      justify-content: center; /* Center items horizontally */
      align-items: center; /* Center items vertically */
      margin-right: 40px;
    }
  }