.client-container {
  position: relative;
  background-image: url('../assets/client.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  height: auto;
  overflow: hidden;
}

.slider-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 150px auto; /* Center horizontally */
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  margin: 20px; /* Add margin between cards */
  border-radius: 0 25% 0 25%;
  overflow: hidden;
  border: 3px double #3B75B2;
  background-color: rgba(21, 31, 40, 0.5);
  transition: box-shadow 0.3s ease, filter 0.3s ease; /* Add filter transition */
  position: relative;
  filter: blur(1px);
}

.card:hover {
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0 0 20px rgba(21, 9, 181, 0.8);
  z-index: 1; /* Bring the hovered card to the front */
  filter: blur(0); /* Remove blur effect on hover */
}

.card:hover ~ .card {
  filter: blur(5px); /* Apply blur effect to other cards */
}

.card-img-top {
  width: 100%;
  height: auto;
  object-fit: cover;
  margin: 20px;
  border: 3px dotted #3B75B2;
  transition: filter 0.3s ease; /* Add filter transition */
}

.card:hover .card-img-top {
  filter: blur(0); /* Remove blur effect on image hover */
}

.card-body h4 {
  margin-top: -10px;
  border-bottom: 2px solid #3B75B2;
  color: antiquewhite;
}

.card-img-bottom {
  max-width: 20%;
  max-height: 20%;
  margin-left: 200px;
  display: none;
}

.card-body {
  text-align: center;
}

.card-body p {
  display: none;
  color: antiquewhite;
}

.card:hover .card-body h4,
.card:hover .card-img-bottom,
.card:hover .card-body p {
  display: block;
}

@media (max-width:879px) {
  .client-container {
    height: auto;
  }

  .slider-wrapper {
    margin: 20px auto; /* Center horizontally */
  }

  .card {
    max-width: 300px; /* Adjust card width for smaller screens */
  }

  .card-img-top {
    max-width: 100%; /* Adjust image width for smaller screens */
  }

  .card-img-bottom {
    max-width: 50%; /* Adjust bottom image width for smaller screens */
    margin-left: 0;
  }
}
