/* styles/Popup.css */


@keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
  }
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
 
.pricepopup-container {
    position: fixed;
    top: 30%;
    right: 5%;
    transform: translate(50%, -50%);
    width: 70%;
    max-width: 400px; /* Limit the maximum width of the popup */
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 100;
    text-align: center;
    border-radius: 10px; /* Add border radius for rounded corners */
    animation: pulse 1s infinite;
  }
  
  .pricered {
    color:#88ff00;
    font-size: large;
  }
  
  .priceclose-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px; /* Increase the font size of the close button */
    background: none;
    border: none;
    color: white;
    cursor: pointer;
  }
  
  .pricepopup-content {
    margin-top: 30px; /* Adjust the margin around the content */
    color: white;
    text-align: center;
  }
  
  .pricepopup-content h1 {
    font-size: 24px; /* Increase the font size of the main heading */
    color: #3B75B2;
  }
  
  .pricepopup-content h2 {
    margin-top: 10px;
    font-size: 20px; /* Increase the font size of the subheading */
    color: #3B75B2;
  }
  
  .pricepopup-form {
    margin-top: 20px; /* Add margin between content and form */
  }
  
  .pricepopup-form label {
    display: block;
    margin-bottom: 10px;
    color: #3B75B2; /* Change label color */
  }
  
  .pricepopup-form input,
  .pricepopup-form select {
    width: 100%;
    padding: 8px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .pricepopup-form button {
    width: 100%;
    padding: 10px;
    
  }
  
  
  .pricevoucher {
    font-weight: bold;
    font-size: medium;
  }
  
  .pricevouchercode {
    font-weight: bold;
    font-size: large;
    color: red;
    cursor: pointer;
  }
  
  .pricepopup-content p {
    margin: 10px 0;
  }
  
  .pricenotification {
    text-align: justify;
  }
  

  
  @media only screen and (max-width: 768px) {
    .pricepopup-container {
     position: absolute;
      right: 20%;
      transform: translateX(50%) translateY(-50%);
    }
  
    .pricepopup-content {
      padding: 0 20px; /* Adjust padding for smaller screens */
    }
  }
  @media only screen and (max-width: 620px) {
    .pricepopup-container {
  
      left: 20%;
    }
  }
  @media only screen and (max-width: 420px) {
    .pricepopup-container {
     position: absolute;
      width: 80%;
      left: 10%;
      top: 50%;
      transform: translateX(50%) translateY(-50%);
    }
  
    .pricepopup-content {
      padding: 0 20px; /* Adjust padding for smaller screens */
    }
  }
  
  @media only screen and (max-width: 1900px) {
    .pricepopup-container {
      right: 5%;
      transform: translateX(50%) translateY(-45%);
    }
  
    .pricepopup-content {
      padding: 0 20px; /* Adjust padding for smaller screens */
    }
  }
  
  @media only screen and (max-width: 1060px) {
    .pricepopup-container {
      right: 25%;
      transform: translateX(50%) translateY(-50%);
    }
  
    .pricepopup-content {
      padding: 0 20px; /* Adjust padding for smaller screens */
    }
  }
  