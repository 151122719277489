
.homepage-container {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}


.smartservices{
margin-top: -200px;
position: relative;
z-index: 1;
}

.Header{
  margin-bottom: -10px;
  object-position:top;
}

body {
  overflow-x: hidden;
}


*{
margin: 0px;
padding: 0px;
}
.slider-container {
  margin: 0px;
  margin-bottom: 20px;
  position: relative;
  overflow: hidden; 
z-index: 0;
}

.smartservices {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}
.h1stake{
font-family: 'Arial', sans-serif;
font-weight: bold;
font-size: 36px;
display: inline-block;
color: #333; /* Adjust the color as needed */
text-align: center;
margin-bottom: 20px;
text-transform: uppercase; /* Uppercase text */
letter-spacing: 2px; /* Adjust letter spacing for style */
border-bottom: 1px solid #FFB302;
padding-bottom: 10px;
margin-top: 50px;
}
.specialoffer {
  position: absolute;
  top: 28px; /* Adjust this value as needed to align with the header */
  left: 56px; /* Adjust the initial position as needed */
  z-index: 10; /* Ensure it's above other content */
animation: swing 2s infinite alternate;/* Apply swinging animation */
  transform-origin: left right; /* Set the rotation origin to the top center */
  cursor: pointer;
 
}

.specialoffer img{
  width: 200px;
}
@keyframes swing {
  0% { transform: rotate(-2deg); }
  50% { transform: rotate(2deg); }
  100% { transform: rotate(-2deg); }
}
.slick-slider {
  width: 100%;

}




/* Styles for Slider Images */
.slick-slide img {
  width: 100%;
  height: 893px;
  object-fit: cover;
  border-radius: 8px;
  margin-top: -30px;
  animation: zoominoutanimate 50s infinite;
  /* Adjust the transition duration for a smoother effect */
}

@keyframes zoominoutanimate {
0% {
  transform: scale(1);
}
50% {
  transform: scale(1.2); /* Zoom in to 120% */
}
100% {
  transform: scale(1); /* Return to original size */
}
}
/* Styles for Slider Arrows */
.slick-arrow {
  font-size: 0px;
  color: #fff;
  background-color: rgba(0, 0, 0, 1); /* Semi-transparent black background for arrows */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 100; 
  cursor: pointer;
  
}

.slick-prev {
  left: 10px;
}

.slick-next {
  right: 10px;
}


.loader-container {
position: fixed;
top: 0;
left: 0;
width: 100vw;
height: 100vh;
display: flex;
align-items: center;
justify-content: center;
background: rgba(0, 0, 0, 1); /* Adjust the background color and transparency */
z-index: 1000; /* Adjust the z-index as needed */
}

.loader-container img {
width: auto;
height: auto;
}

.phata {
  position: absolute;
  top: 4.5%;
  left: 43%;
  transform: translate(-50%, -50%);
  z-index: 2; 
}

.phata img {
  max-width: 43%;
  height: auto;
  border-radius: 10px; /* Adjust the border-radius as needed */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0); /* Add a subtle box shadow */
 /* transform: rotate(12deg); */

}
@media (max-width: 1360px) {
  .slick-slide img {
    height: 100vh; /* Adjust the height for smaller screens */
    margin-top: -15px; /* Adjust the top margin for smaller screens */
  }
  .phata {
    position: absolute;
    top: 4.5%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2; 
  }
}


@media (max-width: 1150px) {
  .slick-slide img {
    height: 100vh; /* Adjust the height for smaller screens */
    margin-top: -15px; /* Adjust the top margin for smaller screens */
  }
  .phata {
    position: absolute;
    top: 4.5%;
    left: 55%;
    transform: translate(-50%, -50%);
    z-index: 2; 
  }
  .phata img {
    max-width: 43%;
    height: auto;
    border-radius: 10px; /* Adjust the border-radius as needed */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0); /* Add a subtle box shadow */
   /* transform: rotate(12deg); */
  
  }
}

@media (max-width: 1090px) {
  .slick-slide img {
    height: 100vh; /* Adjust the height for smaller screens */
    margin-top: -15px; /* Adjust the top margin for smaller screens */
  }
  .phata {
    position: absolute;
    top:15%;
    left: 5%;
    transform: translate(-50%, -50%);
    z-index: 2; 
  }
  .specialoffer{
      top:43px;
      left:9.3%;
    }
    .specialoffer img{
      width: 120px;
    }
  
  .phata img {
    max-width: 43%;
    height: auto;
    border-radius: 10px; /* Adjust the border-radius as needed */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0); /* Add a subtle box shadow */
   /* transform: rotate(12deg); */
  
  }
}


@media (max-width: 990px) {
  .phata {
    position: absolute;
    top:2.5%;
    left: 70%;
    transform: translate(-50%, -50%);
    z-index: 2; 
  }
  .phata img {
    max-width: 20%;
    height: auto;
    border-radius: 10px; /* Adjust the border-radius as needed */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0); /* Add a subtle box shadow */
  }
  .specialoffer{
    top:18.5px;
    left:-0.5%;
    z-index: 0.5; 
  }
  .specialoffer img{
    width: 100px;
  }
}

@media (max-width: 690px) {
  .phata {
    position: absolute;
    top:2.5%;
    left: 80%;
    transform: translate(-50%, -50%);
    z-index: 2; 
  }
  .phata img {
    max-width: 80%;
    height: auto;
    border-radius: 10px; /* Adjust the border-radius as needed */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0); /* Add a subtle box shadow */

  }
}



@media (max-width: 420px) {
  .phata {
    position: absolute;
    top:9%;
    right: 9%;
    transform: translate(-50%, -50%);
    z-index: 2; 
  }
  .phata img {
    max-width: 150%;
    height: auto;
    border-radius: 10px; /* Adjust the border-radius as needed */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0); /* Add a subtle box shadow */
   /* transform: rotate(12deg); */
  
  }
  .specialoffer{
    top:15px;
    left:-0.5%;
    z-index: 0.2; 
  }
  

}