/* styles/Popup.css */

.popup-container {
  position: fixed;
  top: 45%;
  right: 15%;
  transform: translate(50%, -50%);
  width: 70%;
  max-width: 400px; /* Limit the maximum width of the popup */
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 100;
  text-align: center;
  border-radius: 10px; /* Add border radius for rounded corners */
}

.red {
  color:#88ff00;
  font-size: large;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px; /* Increase the font size of the close button */
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}

.popup-content {
  margin-top: 30px; /* Adjust the margin around the content */
  color: white;
  text-align: center;
}

.popup-content h1 {
  font-size: 24px; /* Increase the font size of the main heading */
  color: #3B75B2;
}

.popup-content h2 {
  margin-top: 10px;
  font-size: 20px; /* Increase the font size of the subheading */
  color: #3B75B2;
}

.popup-form {
  margin-top: 20px; /* Add margin between content and form */
}

.popup-form label {
  display: block;
  margin-bottom: 10px;
  color: #3B75B2; /* Change label color */
}

.popup-form input,
.popup-form select {
  width: 100%;
  padding: 8px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.popup-form button {
  width: 100%;
  padding: 10px;
  
}


.voucher {
  font-weight: bold;
  font-size: medium;
}

.vouchercode {
  font-weight: bold;
  font-size: large;
  color: red;
  cursor: pointer;
}

.popup-content p {
  margin: 10px 0;
}

.notification {
  text-align: justify;
}


@media only screen and (max-width: 768px) {
  .popup-container {
   position: absolute;
    width: 90%;
    max-width: 100%;
    right: 50%;
    transform: translateX(50%) translateY(-50%);
  }

  .popup-content {
    padding: 0 20px; /* Adjust padding for smaller screens */
  }
}

@media only screen and (max-width: 420px) {
  .popup-container {
   position: absolute;
    width: 90%;
    max-width: 100%;
    right: 50%;
    top: 50%;
    transform: translateX(50%) translateY(-50%);
  }

  .popup-content {
    padding: 0 20px; /* Adjust padding for smaller screens */
  }
}

@media only screen and (max-width: 1900px) {
  .popup-container {
    right: 20%;
    transform: translateX(50%) translateY(-45%);
  }

  .popup-content {
    padding: 0 20px; /* Adjust padding for smaller screens */
  }
}

@media only screen and (max-width: 1060px) {
  .popup-container {
    right: 50%;
    transform: translateX(50%) translateY(-45%);
  }

  .popup-content {
    padding: 0 20px; /* Adjust padding for smaller screens */
  }
}
