@keyframes zoomInOut {
  0%, 100% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(0.8);
    opacity: 0.8;
  }
}

.ourlocation-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
}

.location-para {
  position: absolute;
  top: 8%;
  left: 34%;
  font-weight: bold;
  font-size: large;
  transform: translate(-50%, -50%);
  text-align: center;
  max-width: 600px;
  background-color: rgba(0, 0, 0, 0.3); 
  padding: 20px;
  border-radius: 50px;
  color: #3B75B2;
  z-index: 100;
  animation: zoomInOut 3s ease-in-out infinite;
}

.wonderlocation {
  width: 100%; 
  border-radius: 8px;
}

@media (max-width: 1500px) {
  .location-para {
    max-width: 700px;
    left: 25%;
  }
}
@media (max-width: 1000px) {
  .location-para {
    max-width: 700px;
    left: 15%;
  }
}

@media (max-width: 900px) {
  .location-para {
    max-width: 450px;
    font-size: 12px;
    left: 20%;
  }
}

@media (max-width: 620px) {
  .location-para {
    max-width: 600px;
    top: 2%;
    font-size: 6px;
    left: 8%;
  }
}